#datepicker {
  position: absolute;
  z-index: 10;
  background: white;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
}

.ui-datepicker-current-day{
  background: #ff354e;
  border-radius: 50%;
  color: white;
}
.ui-datepicker-current-day a{
  color:white;
}

/* .ui-state-disabled{
  background: grey;
  font-size:15px;
  border-radius: 50%;
} */
.ui-state-disabled{
  /* background: white; */
  color:#d7d7d7;
}


.ui-datepicker {
    border: solid 1.2px #000000;
    width: 216px;
    height: auto;
    margin: 5px auto 0;
    font: 9pt Arial, sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5); */
  }
  
  .ui-datepicker a {
    text-decoration: none;
    font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  }
  
  .ui-datepicker table {
    width: 100%;
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    margin-top: 10px;
  }
  
  .ui-datepicker-title {
    text-align: center;
    background: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size:12px !important;
    font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  }
  .ui-datepicker-year,
  .ui-datepicker-month{
    color: white;
  }

  span[title=Sunday]{
    color:red;
  }

  .ui-datepicker-calendar thead tr th span{
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;

  }

  
  .ui-datepicker-calendar{
    text-align: center;
  }
  
.ui-datepicker-calendar tbody tr{
  padding: 5px 5px 5px 5px
}

.ui-datepicker-calendar tbody tr td{
  padding: 7px 7px 7px 7px
}
/* .ui-datepicker-next{
  background-image: url('/@resources/images/board/kr-calendar-page-nav-next.svg')
} */

.ui-widget-header{
  width: 119%;
  left: -20px;
  position: relative;
}
.ui-widget-header .ui-datepicker-prev .ui-icon {
  background-image: url('/@resources/images/board/kr-calendar-page-prev-next.svg');
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: 18px;
}
.ui-widget-header .ui-datepicker-next .ui-icon {
  background-image: url('/@resources/images/board/kr-calendar-page-nav-next.svg');
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: 18px;
}

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  /* left: .5em;
  top: .3em; */
}
.ui-datepicker .ui-datepicker-prev span{
  display: block;
  position: absolute;
  left: 50%;
  margin-top: 8px;
  margin-left: 7px;
  top: 50%;
}
 .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-top: 7px;
  margin-left: -25px;
  top: 50%;
  /* margin-top: -8px; */
}

  .ui-datepicker-prev {
    float: left;
    background-position: center -30px;
  }
  .ui-datepicker-next {
    float: right;
    background-position: center 0px;
  }
  
  /* .ui-datepicker thead {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top,  #f7f7f7 0%, #f1f1f1 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f7f7f7), color-stop(100%,#f1f1f1));
    background-image: -webkit-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
    background-image: -o-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
    background-image: -ms-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
    background-image: linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#f1f1f1',GradientType=0 );
    border-bottom: 1px solid #bbb;
  } */
  
  .ui-datepicker th {
    text-transform: uppercase;
    font-size: 6pt;
    padding: 5px 0;
    color: #666666;
    text-shadow: 1px 0px 0px #fff;
    filter: dropshadow(color=#fff, offx=1, offy=0);
  }

  .ui-datepicker-calendar tbody tr td a{
    font-size:12px !important;
  }